<template>
  <div v-loading.fullscreen.lock="loading" id="settings_verify_email">
    <inner-page-title title="Verify email"></inner-page-title>

    <div v-if="emailVerified" style="text-align: center" class="container settings-options">
      <div class="image-container">
        <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'check-double']"></font-awesome-icon>
      </div>
      <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">You are good!</span>
      <p>Your email is already verified, there's nothing you need to do.</p>
    </div>

    <div v-if="!emailVerified" style="text-align: center" class="container settings-options">
      <div v-if="status == 'EMAIL_NOT_SENT'">
        <div class="image-container">
          <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'envelope']"></font-awesome-icon>
        </div>
        <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Your email isn't verified!</span>
        <p>To verify your email address, we will send you an email with a link to verify it's you.</p>

        <div style="margin-top: 20px">
          <el-button type="primary" class="secondary-color" @click="verifyEmail">Verify now</el-button>
        </div>
      </div>

      <div v-if="status == 'EMAIL_SENT'">
        <div class="image-container">
          <font-awesome-icon style="color: rgba(0,0,0,0.6)" :icon="['fas', 'envelope-open-text']"></font-awesome-icon>
        </div>
        <span style="display: block; font-size: 20px; margin-bottom: 5px; text-align: center">Check your inbox!</span>
        <p>We have sent you an email to <span style="font-weight: 500">{{ this.$store.getters.user.data.email }}</span>. Please check your inbox or spam folder for an email from <span style="font-weight: 500">Sharo Mobility</span>.</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data: () => ({
    loading: false,
    status: 'EMAIL_NOT_SENT' /* 'EMAIL_SENT', 'EMAIL_NOT_SENT' */
  }),

  mounted() {
    // Refresh token to get new claims
    this.$store.dispatch('checkUserClaims', true);
  },

  methods: {
    verifyEmail() {
      this.loading = true;
      
      this.$SharoAPI({ action: 'userAuthHelper', data: { type: 'generateEmailVerificationLink', email: this.$store.getters.user.data.email }}).then(result => {
        console.log(result);
        if (result.data.error) {
          return this.$message({ type: 'error', message: result.data.error_message });
        }

        this.$message({ type: 'success', message: result.data.message });
        this.status = 'EMAIL_SENT';
        localStorage.setItem("emailForSignIn", this.$store.getters.user.data.email);
      }).finally(() => { this.loading = false; });
    }
  },

  computed: {
    emailVerified() {
      return this.$store.getters.userClaims && this.$store.getters.userClaims.email_verified;
    }
  }
}
</script>

<style lang="scss" scoped>
  .container.settings-options {
    max-width: 600px;

    .form-item {
      margin-bottom: 10px;
    }
  }

  .image-container {
    border-radius: 50%;
    background-color: #d1f1ff;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    margin: auto;
    margin-bottom: 15px;
  }

</style>