var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: { id: "settings_verify_email" }
    },
    [
      _c("inner-page-title", { attrs: { title: "Verify email" } }),
      _vm.emailVerified
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _c(
                "div",
                { staticClass: "image-container" },
                [
                  _c("font-awesome-icon", {
                    staticStyle: { color: "rgba(0,0,0,0.6)" },
                    attrs: { icon: ["fas", "check-double"] }
                  })
                ],
                1
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "20px",
                    "margin-bottom": "5px",
                    "text-align": "center"
                  }
                },
                [_vm._v("You are good!")]
              ),
              _c("p", [
                _vm._v(
                  "Your email is already verified, there's nothing you need to do."
                )
              ])
            ]
          )
        : _vm._e(),
      !_vm.emailVerified
        ? _c(
            "div",
            {
              staticClass: "container settings-options",
              staticStyle: { "text-align": "center" }
            },
            [
              _vm.status == "EMAIL_NOT_SENT"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "image-container" },
                      [
                        _c("font-awesome-icon", {
                          staticStyle: { color: "rgba(0,0,0,0.6)" },
                          attrs: { icon: ["fas", "envelope"] }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("Your email isn't verified!")]
                    ),
                    _c("p", [
                      _vm._v(
                        "To verify your email address, we will send you an email with a link to verify it's you."
                      )
                    ]),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "secondary-color",
                            attrs: { type: "primary" },
                            on: { click: _vm.verifyEmail }
                          },
                          [_vm._v("Verify now")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm.status == "EMAIL_SENT"
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "image-container" },
                      [
                        _c("font-awesome-icon", {
                          staticStyle: { color: "rgba(0,0,0,0.6)" },
                          attrs: { icon: ["fas", "envelope-open-text"] }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "font-size": "20px",
                          "margin-bottom": "5px",
                          "text-align": "center"
                        }
                      },
                      [_vm._v("Check your inbox!")]
                    ),
                    _c("p", [
                      _vm._v("We have sent you an email to "),
                      _c("span", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v(_vm._s(this.$store.getters.user.data.email))
                      ]),
                      _vm._v(
                        ". Please check your inbox or spam folder for an email from "
                      ),
                      _c("span", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v("Sharo Mobility")
                      ]),
                      _vm._v(".")
                    ])
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }